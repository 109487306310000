<template>
  <div>
    <div
      class="block-icon-table-facture"
      v-if="permission && awesome"
      @click.stop="openModal"
      :title="title"
    >
      <font-awesome-icon icon="eye" small />
    </div>
    <div
      v-if="permission && !awesome"
      @click.stop="openModal"
      class="action-title"
    >
      {{ title }}
    </div>
    <v-dialog
      v-model="dialog"
      :max-width="mode != 'simple' && indexTab == '1' ? '90%' : '920px'"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">{{ title }} </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text
          v-if="detailsBlock"
          class="pb-6 pt-2 d-flex justify-center"
        >
          <template v-if="isAvoir === false">
            <v-btn
              text
              @click="$emit('visualisationPrecedent')"
              title="Précédent"
              :disabled="this.indexBegin === 0"
              class="block-icon-show-facture"
            >
              Précédent
            </v-btn>
            <v-btn
              text
              @click="$emit('visualisationAvant')"
              title="Suivant"
              :disabled="indexBegin == indexEnd"
              class="block-icon-show-facture"
            >
              Suivant
            </v-btn>
            <v-btn
              v-if="checkPermission('FLTFP')"
              text
              title="Télecharger facture"
              class="block-icon-show-facture "
              @click="$emit('FacuresToDownloadOnePDF')"
            >
              <font-awesome-icon icon="cloud-download-alt" class="mt-1" />
            </v-btn>

            <v-btn
              v-if="checkPermission('FLDF')"
              text
              @click="$emit('onClickNavigate')"
              title="Dupliquer facture"
              class="block-icon-show-facture"
            >
              <font-awesome-icon icon="clone" />
            </v-btn>
          </template>
        </v-card-text>
        <v-card-text
          class="body-card center-text  mt-2"
          v-if="mode == 'simple'"
        >
          <iframe
            height="1100"
            width="760"
            :src="factureToShow + '#toolbar=0'"
          ></iframe>
        </v-card-text>
        <v-card-text class="body-card center-text  mt-2" v-else>
          <v-tabs
            background-color="#f1edfb"
            color="#704ad1"
            class="tabs-visualiser-fac-ecriture"
            v-model="indexTab"
          >
            <v-tab active class="tab-visualise">
              <span class="tab-text-bold">
                Facture
              </span>
            </v-tab>
            <v-tab-item>
              <iframe
                class="mt-1"
                height="1100"
                width="760"
                :src="factureToShow + '#toolbar=0'"
              ></iframe>
            </v-tab-item>
            <v-tab class="tab-visualise">
              <span class="tab-text-bold">
                Écriture comptable
              </span>
            </v-tab>
            <v-tab-item>
              <div class="mt-1">
                <div class="loading-custom-ecriture" v-if="loadingEcriture">
                  <Half-circle-spinner
                    :animation-duration="1000"
                    :size="50"
                    :color="'#704ad1'"
                    class="loeder"
                  />
                </div>
                <template v-else>
                  <div class="cadre-block-filter">
                    <div class="right-block"></div>
                    <div class="left-block">
                      <v-tooltip bottom color="#311B92">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="block-icon mr-2"
                            v-on="on"
                            v-bind="attrs"
                            @click.prevent="handelRefreshEcriture"
                          >
                            <font-awesome-icon icon="sync-alt" />
                          </div>
                        </template>
                        <span>Actualiser écriture comptable</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <div class="refresh-loader">
                    <div
                      class="loading-custom-ecriture"
                      v-if="loadingRefreshCompta"
                    >
                      <Half-circle-spinner
                        :animation-duration="1000"
                        :size="50"
                        :color="'#704ad1'"
                        class="loeder"
                      />
                    </div>
                  </div>
                  <v-data-table
                    v-model="selectedTableE"
                    :headers="fields"
                    :items="ecritureToShow"
                    class="table-ecritute-compta"
                    hide-default-footer
                    :calculate-widths="false"
                    :fixed-header="true"
                    hide-action
                    disable-sort
                    no-data-text="Il n'y a aucun enregistrement à afficher"
                  >
                    <template v-slot:[`item.date`]="{ item }">
                      <td class="custom-cell">
                        {{ item.date ? item.date : item.created_at }}
                      </td>
                    </template>
                    <template v-slot:[`item.compte`]="{ item }">
                      <td class="custom-cell">{{ item.compte }}</td>
                    </template>
                    <template v-slot:[`item.tiers`]="{ item }">
                      <td class="custom-cell">{{ item.tiers }}</td>
                    </template>
                    <template v-slot:[`item.fact`]="{ item }">
                      <td class="custom-cell">{{ item.reference }}</td>
                    </template>
                    <template v-slot:[`item.journal`]="{ item }">
                      <td class="custom-cell">{{ item.journal }}</td>
                    </template>
                    <template v-slot:[`item.label_additional`]="{ item }">
                      <td class="custom-cell">{{ item.label_additional }}</td>
                    </template>
                    <template v-slot:[`item.num_dossier`]="{ item }">
                      <td class="custom-cell">{{ item.num_dossier }}</td>
                    </template>
                    <template v-slot:[`item.nom_prenom`]="{ item }">
                      <td class="custom-cell">{{ item.nom_prenom }}</td>
                    </template>
                    <template v-slot:[`item.tva`]="{ item }">
                      <td class="custom-cell">{{ item.tva }}</td>
                    </template>
                    <template v-slot:[`item.credit`]="{ item }">
                      <td class="custom-cell">
                        {{ item.credit ? item.credit + ' €' : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.debit`]="{ item }">
                      <td class="custom-cell">
                        {{ item.debit ? item.debit + ' €' : '-' }}
                      </td>
                    </template>
                  </v-data-table></template
                >
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    facture: { required: true },
    permission: { required: true },
    awesome: { required: false },
    title: { default: 'Afficher facture' },
    mode: { default: 'simple' },
    factureSource: { default: null, required: false },
    detailsBlock: { default: false },
    indexBegin: { default: 0 },
    isAvoir: { default: true },
    indexEnd: { required: false }
  },
  components: {},
  data() {
    return {
      indexTab: 'tab-0',
      dialog: false,
      factureToShow: null,
      error: null,
      ecritureToShow: [],
      selectedTableE: [],
      loadingEcriture: false,
      fields: [
        {
          value: 'fact',
          text: 'Numéro de Facture'
        },
        {
          value: 'date',
          text: 'Date'
        },
        {
          value: 'journal',
          text: 'Journal'
        },
        {
          value: 'compte',
          text: 'Compte'
        },
        {
          value: 'tiers',
          text: 'Tiers'
        },
        {
          value: 'nom_prenom',
          text: 'Nom prénom client'
        },
        {
          value: 'tva',
          text: 'Taux de TVA'
        },
        {
          value: 'debit',
          text: 'D'
        },
        {
          value: 'credit',
          text: 'C'
        }
      ],
      loadingRefreshCompta: false
    };
  },
  methods: {
    ...mapActions([
      'displayFact',
      'displayEcrCompta',
      'refreshFiltredFactureCompta'
    ]),
    async openModal() {
      this.dialog = true;
      this.error = null;
      this.$emit('eventOpenModalVisual');
      if (this.mode == 'double') {
        this.loadingEcriture = true;
        const resCompta = await this.displayEcrCompta(this.facture);
        if (resCompta.success == true) {
          this.ecritureToShow = resCompta.response;
        }
        this.loadingEcriture = false;
      }
      const response = await this.displayFact(this.facture);
      if (response.success == true) {
        this.factureToShow = response.response;
      }
    },
    async visualisation(id) {
      if (this.mode == 'double') {
        this.loadingEcriture = true;
        const resCompta = await this.displayEcrCompta(id);
        if (resCompta.success == true) {
          this.ecritureToShow = resCompta.response;
        }
        this.loadingEcriture = false;
      }
      const response = await this.displayFact(id);
      if (response.success == true) {
        this.factureToShow = response.response;
      }
    },
    closeModal() {
      this.dialog = false;
      this.factureToShow = null;
      this.ecritureToShow = [];
      this.error = null;
      this.indexTab = 'tab-0';
    },
    async handelRefreshEcriture() {
      this.loadingRefreshCompta = true;
      this.error = null;
      const response = await this.refreshFiltredFactureCompta({
        id: this.facture
      });
      if (response.succes) {
        this.ecritureToShow = response.data.data;
        if (this.factureSource) {
          if (this.factureSource.id == this.facture) {
            this.factureSource.anomalie = response.data.anomalie;
          }
          if (this.factureSource.origine_id == this.facture) {
            this.factureSource.anomalie_origine = response.data.anomalie;
          }
        }
      } else {
        this.error = response.error;
      }
      this.loadingRefreshCompta = false;
    }
  },
  computed: {
    ...mapGetters(['checkPermission'])
  }
};
</script>
<style scoped lang="scss">
.tabs-visualiser-fac-ecriture {
  width: 100%;
  .cadre-block-filter {
    background: transparent;
    border: none;
  }
  .tab-visualise {
    width: 50%;
  }
  .v-slide-group__content {
    justify-content: space-evenly;
    display: flex;
  }
  .loading-custom-ecriture {
    width: 100%;
    padding-top: 15%;
    padding-bottom: 15%;
    padding-left: 45%;
  }
  .refresh-loader {
    .loading-custom-ecriture {
      padding-top: 0%;
      padding-bottom: 2%;
    }
  }
}
.block-icon-show-facture {
  background-color: #f1edfb !important;
  box-shadow: none !important;
  padding: 6.5px;
  font-size: 15px;
  font-weight: 600;
  color: #704ad1;
  border-radius: 4px;
  padding: 0px 5px;
  border: 1px solid #704ad1;
  box-shadow: 5px 6px 8px #9799d67a;
  cursor: pointer;
  height: 32px !important;
  min-width: 29px !important;
  z-index: 1;
  margin-right: 10px;
  svg {
    color: #704ad1;
    overflow: hidden;
    vertical-align: middle;
    transform: rotateZ(0deg);
    height: 20px;
    width: 20px;
  }
}
</style>
